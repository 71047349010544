<template>
  <v-container fluid>
    <v-row v-if="drs.site_location === 'Travel / Yard Work'">
      <v-col class="col-8">
        <v-row>
          <v-col class="pt-3 pb-2">
            <h1>View Daily Return Sheet #{{ drs.pal_drs_id }} for Travel / Yard Work</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">{{ drs.notes }}</v-col>
        </v-row>
        <v-row v-if="drs.daily_return_sheets_workers[0]">
          <v-col v-if="drs.daily_return_sheets_workers[0].crew_member" class="pt-3 pb-1">
            Chargehand:
            {{ drs.daily_return_sheets_workers[0].crew_member.personnel.first_name }}
            {{ drs.daily_return_sheets_workers[0].crew_member.personnel.last_name }}
          </v-col>
        </v-row>
        <div v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
          <v-row v-if="index > 0">
            <v-col class="pt-3 pb-2">
              Worker:
              {{ worker.crew_member.personnel.first_name }}
              {{ worker.crew_member.personnel.last_name }}
            </v-col>
          </v-row>
        </div>
        <v-row class="pt-10">
          <v-col>
            <label v-if="drs.daily_return_sheet_special_bonnuses.length > 0">
              Special Bonuses
            </label>
            <v-simple-table
              dense
              v-if="drs.daily_return_sheet_special_bonnuses.length > 0">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
                <tr v-for="(sb) in drs.daily_return_sheet_special_bonnuses" :key="(sb.id)">
                  <td>{{ sb.description }}</td>
                  <td>{{ sb.quantity }}</td>
                  <td>{{ sb.bonus_rate | currency }}</td>
                  <td>&pound;{{ sb.total }}</td>
                </tr>
              </thead>
            </v-simple-table>
            <label>Work Hours / Bonuses</label>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Overnight</th>
                  <th>Bonus</th>
                </tr>
                <tr v-for="(crew) in drs.daily_return_sheets_workers" :key="(crew.id)">
                  <td>{{ crew.crew_member.name }}</td>
                  <td>{{ crew.hours }}</td>
                  <td>
                    <v-simple-checkbox disabled :ripple="false" v-model="crew.overnight"></v-simple-checkbox>
                  </td>
                  <td>
                    {{ individualBonus | currency }}
                  </td>
                </tr>
              </thead>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="drs.minimum_bonus">
          <v-col>
            <p v-if="(totalBonus < 20)">
              <v-select
                label="A minimum bonus of £20 will be applied. Do you want to remove this minimum bonus?"
                :items="boolean"
                v-model="drs.minimum_bonus"
              ></v-select>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-1">
            <router-link :to="`/drs/addHours/${drs.id}`">
              <v-btn small>Add Hours</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col-8">
        <v-row>
          <v-col
            class="pt-3 pb-2"
            v-if="drs.quotation_id === 0">
            <h1>View Internal / Yard Work Daily Return Sheet #{{ drs.id }}</h1>
          </v-col>
          <v-col
            class="pt-3 pb-2"
            v-else>
            <h1>View Daily Return Sheet #{{ drs.pal_drs_id }} for {{ drs.job_no }}</h1>
            <p>
              <router-link :to="`/live-jobs/overview/${drs.quotation_id}`">
                <v-btn small>Job Overview</v-btn>
              </router-link>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="drs.quotation">
          <v-col class="pt-3 pb-2">
            <h2>Job value: {{ drs.quotation.total_value | priceInPounds | currency }}</h2>
          </v-col>
        </v-row>
        <v-row v-if="drs.quotation">
          <v-col class="pt-2 pb-2">
            <h4 class="pb-2">Daily Return Sheets for the Job</h4>
            <span v-for="(sheet, index) in jobDailyReturnSheets" :key="`s_${index}`">
              <v-btn
                v-if="currentDrsId === sheet.id"
                color="lime lighten-3"
                class="mr-4 mb-4"
                small>DRS #{{ sheet.id }} {{ sheet.work_date | tinyDate }}</v-btn>
              <v-btn
                v-else
                @click="currentDrsId = sheet.id"
                class="mr-4 mb-4"
                small>DRS #{{ sheet.id }} {{ sheet.work_date | tinyDate }}</v-btn>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="drs.quotation_id !== 0">
          <v-col class="pt-3 pb-2">
            Customer: {{ drs.quotation.customer.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Date for the DRS: {{ drs.work_date | tinyDate }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <span v-if="drs.department">Department: {{ drs.department.name }}</span>
            <span v-else>Department not set</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <span v-if="drs.surface_type">Surface Type: {{ drs.surface_type.surface_type }}</span>
            <span v-else>Surface Type not set</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <span v-if="drs.surface_condition">Surface Conditions: {{ drs.surface_condition.surface_condition }}</span>
            <span v-else>Surface Conditions not set</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <span v-if="drs.weather_condition">Weather Conditions: {{ drs.weather_condition.description }}</span>
            <span v-else>Weather Conditions not set</span>
          </v-col>
        </v-row>
        <v-row v-if="drs.quotation_id !== 0">
          <v-col class="pt-3 pb-2">
            Site: {{ drs.quotation.site_location }}
          </v-col>
        </v-row>
        <v-row v-if="drs.daily_return_sheets_workers[0]">
          <v-col v-if="drs.daily_return_sheets_workers[0].crew_member" class="pt-3 pb-1">
            Chargehand:
            {{ drs.daily_return_sheets_workers[0].crew_member.personnel.first_name }}
            {{ drs.daily_return_sheets_workers[0].crew_member.personnel.last_name }}
          </v-col>
        </v-row>
        <div v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
          <v-row v-if="index > 0">
            <v-col class="pt-3 pb-2">
              Worker:
              {{ worker.crew_member.personnel.first_name }}
              {{ worker.crew_member.personnel.last_name }}
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="pt-3 pb-1">
            <router-link :to="`/drs/addHours/${drs.id}`">
              <v-btn small>Add Hours</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Vehicle Reg: {{ drs.vehicle }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            2nd Vehicle Reg: {{ drs.vehicle_two }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            3rd Vehicle Reg: {{ drs.vehicle_three }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Mileage: {{ drs.mileage }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Drawing no: {{ drs.drawing_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Revision no: {{ drs.revision_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            Type of works: {{ drs.job_type.type }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <v-checkbox disabled dense :ripple="false" label="Overbanding Grout" v-model="drs.overbanding_grout"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Beads" v-model="drs.beads"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Epoxy" v-model="drs.epoxy"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Thermoplastic" v-model="drs.thermoplastic"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Paint" v-model="drs.paint"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="MMA" v-model="drs.mma"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Anti Skid" v-model="drs.anti_skid"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Tack Coat" v-model="drs.tack_coat"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Remedial Work" v-model="drs.remedial_work"></v-checkbox>
            <v-checkbox disabled dense :ripple="false" label="Sealer" v-model="drs.sealer"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-2">
            <hr />
            <p class="pt-4">Health and Safety</p>
            <v-checkbox
              disabled
              dense
              :ripple="false"
              label="Tick to confirm all PPE was available and in good condition"
              v-model="drs.ppe_good_condition"></v-checkbox>
            <hr />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>Materials Used</label>
            <v-simple-table class="mt-2" dense>
              <thead>
                <tr>
                  <th>Material Type</th>
                  <th>Colour</th>
                  <th>Batch No</th>
                  <th>No of Bags</th>
                  <th>Material Temp</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(material, index) in drs.daily_returns_sheets_material_usages"
                  :key="`mt_${index}`">
                  <td>{{ material.type }}</td>
                  <td>{{ material.colour }}</td>
                  <td>{{ material.batch_number }}</td>
                  <td>{{ material.no_of_bags }}</td>
                  <td>{{ material.material_temperature }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>Bill of Quantities</label>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th style="width:70%;padding-left:0;">Description</th>
                  <th style="width:10%;padding-left:0;">Rate</th>
                  <th style="width:10%;padding-left:0;">Quantity</th>
                  <th class="text-right" style="width:10%;padding-left:0;">Value</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td colspan="4" class="text-right">Total: {{ totalBonus }}</td>
                </tr>
              </tfoot>
              <tbody v-if="sections.length === 0">
                <tr v-for="(boq, index) in drs.daily_return_sheets_bonus_payments" :key="`b_${index}`">
                  <td>{{ boq.bonus_rate.description }}</td>
                  <td>£{{ boq.rate }}</td>
                  <td>{{ boq.quantity}}</td>
                  <td>{{ (boq.quantity * boq.rate) | currency }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(section, index) in sections" :key="`sec_${index}`">
                  <td class="pl-0 pr-0" colspan="4">
                    <table style="width:100%">
                      <thead>
                        <tr>
                          <th style="width:100%; text-align:left; padding-left:0;" colspan="4">
                            {{ section.title }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(boq, index) in section.boq" :key="`sb_${index}`">
                          <td style="width:70%">{{ boq.bonus_rate.description }}</td>
                          <td style="width:10%">£{{ boq.rate }}</td>
                          <td style="width:10%">{{ boq.quantity}}</td>
                          <td style="width:10%">{{ (boq.quantity * boq.rate) | currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p>Misc BOQs</p>
            <v-simple-table v-if="drs.drs_misc_boq_1_description">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ drs.drs_misc_boq_1_description }}</td>
                  <td>{{ drs.drs_misc_boq_1_qty }}</td>
                </tr>
                <tr v-if="drs_misc_boq_2_description">
                  <td>{{ drs.drs_misc_boq_2_description }}</td>
                  <td>{{ drs.drs_misc_boq_2_qty }}</td>
                </tr>
                <tr v-if="drs.drs_misc_boq_3_description">
                  <td>{{ drs.drs_misc_boq_3_description }}</td>
                  <td>{{ drs.drs_misc_boq_3_qty }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="pt-2">
          <v-col>
            <router-link :to="`/drs/update/${drs.id}`">
              <v-btn small>Update DRS</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col>
            <label v-if="drs.daily_return_sheet_special_bonnuses.length > 0">
              Special Bonuses
            </label>
            <v-simple-table
              dense
              v-if="drs.daily_return_sheet_special_bonnuses.length > 0">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
                <tr v-for="(sb) in drs.daily_return_sheet_special_bonnuses" :key="(sb.id)">
                  <td>{{ sb.description }}</td>
                  <td>{{ sb.quantity }}</td>
                  <td>{{ sb.bonus_rate | currency }}</td>
                  <td>&pound;{{ sb.total }}</td>
                </tr>
              </thead>
            </v-simple-table><br />
            <label>Work Hours / Bonuses</label>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Overnight</th>
                  <th>Working Away (+50%)</th>
                  <th>Working Nights (+33%)</th>
                  <th>Bonus</th>
                  <th>Pay bonus?</th>
                </tr>
                <tr v-for="(crew) in drs.daily_return_sheets_workers" :key="(crew.id)">
                  <td><span v-if="crew.crew_member">{{ crew.crew_member.name }}</span></td>
                  <td>{{ crew.hours }}</td>
                  <td>
                    <v-simple-checkbox disabled :ripple="false" v-model="crew.overnight"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-simple-checkbox disabled :ripple="false" v-model="crew.working_away"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-simple-checkbox disabled :ripple="false" v-model="crew.working_nights"></v-simple-checkbox>
                  </td>
                  <td>
                    <span v-if="!crew.working_away && !crew.working_nights">
                      {{ individualBonus | currency }}
                    </span>
                    <span v-if="crew.working_away && !crew.working_nights">
                      {{ ((individualBonus / 2) + individualBonus) | currency }}
                    </span>
                    <span v-if="!crew.working_away && crew.working_nights">
                      {{ ((individualBonus * 0.33) + individualBonus) | currency }}
                    </span>
                    <span v-if="crew.working_away && crew.working_nights">
                      {{ (((individualBonus * 0.33) + (individualBonus / 2)) + individualBonus) | currency }}
                    </span>
                  </td>
                  <td>
                    <v-checkbox
                      @change="updatePayBonus(crew)"
                      :ripple="false"
                      v-model="crew.pay_bonus"></v-checkbox>
                  </td>
                </tr>
              </thead>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="drs.minimum_bonus === false">
          <v-col>
            <p v-if="(individualBonus < 10 || individualBonus < 20 && drs.num_for_bonus === 1)">
              Do you wish to apply a minimum bonus?<br />
              <v-btn @click="applyMinimumBonus" x-small>Apply Minimum Bonus</v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p>
              You have applied a minimum bonus of £20.<br />
              <v-btn @click="removeMinimumBonus" x-small>Remove</v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-1">
            <router-link :to="`/drs/addHours/${drs.id}`">
              <v-btn small>Add Hours</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="pt-2">
          <v-col>
            <router-link :to="`/drs/update/${drs.id}`">
              <v-btn small>Update DRS</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="drs.daily_return_sheets_images.length > 0">
          <v-col>
            <hr />
            <p class="mt-4">Images from the DRS:</p>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>
                    <img
                      class="small-image mr-5"
                      v-for="(image, index) in drs.daily_return_sheets_images"
                      :src="image"
                      :key="index" />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <hr />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col class="pt-0 pb-2">
            <p>
              Is all the available work complete / no return visit required?
              <span v-if="drs.work_complete === 1">Yes</span>
              <span v-else>No</span>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="drs.incomplete_work_reason">
          <v-col class="pt-0 pb-2">
            If not complete, why: {{ drs.incomplete_work_reason }}
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col class="pt-0 pb-2">
            <p>
              Is this a phased visit?
              <span v-if="drs.phased_visit === 1">Yes</span>
              <span v-else>No</span>
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col class="pt-0 pb-2">
            <p>
              Total Job Complete?
              <span v-if="drs.job_complete === 1">Yes</span>
              <span v-else>No</span>
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col class="pt-0 pb-2">
            <p>
              Are there any Disclaimers, Absolvency or extras to record?
              <span v-if="drs.disclaimers_to_record === 1">Yes</span>
              <span v-else>No</span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Notes: {{ drs.notes }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Signed off by (customer): {{ drs.signed_by }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Customer comments: {{ drs.customer_comment }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Signed off by (team member): {{ drs.sign_off_team_member_name }}
          </v-col>
        </v-row>
        <v-row class="pb-10">
          <v-col class="pt-3 pb-1">
            <router-link :to="`/drs/addHours/${drs.id}`">
              <v-btn small>Add Hours</v-btn>
            </router-link>
            &nbsp;
            <router-link :to="`/drs/update/${drs.id}`">
              <v-btn small>Update DRS</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <router-link :to="`/drs/preview/${drs.id}`">
              <v-btn small>Preview</v-btn>
            </router-link>
            &nbsp;
            <v-btn small>Print Photos</v-btn>
            &nbsp;
            <v-btn small>Email Client</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-4 text-right">
        <router-link :to="`/drs/preview/${drs.id}`">
          <v-btn small>Preview</v-btn>
        </router-link>
        &nbsp;
        <v-btn small>Print Photos</v-btn>
        &nbsp;
        <v-btn small>Email Client</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      individualBonus: 0,
      drs: {
        job_type : {},
        daily_return_sheets_images: [],
        daily_return_sheets_workers: [
          { crew_member: { personnel: {} } },
        ],
        quotation: {
          customer: {
            name: '',
          }
        },
        drs_misc_boq_1_description: '',
        drs_misc_boq_2_description: '',
        drs_misc_boq_3_description: '',
        daily_return_sheet_special_bonnuses: [],
      },
      currentDrsId: 0,
      jobDailyReturnSheets: [],
      changeCount: 0,
      sections: [],
      totalBonus: 0,
    };
  },
  watch: {
    currentDrsId() {
      if (this.changeCount > 0) {
        this.getDrs();
      }
      this.changeCount += 1;
    },
  },
  methods: {
    print() {
      window.print();
    },
    updatePayBonus(crew) {
      const postData = {};
      postData.crew = crew;
      axios.post(`/dailyReturnSheetsWorkers/updatePayBonus.json?token=${this.token}`, postData)
        .then(() => {
          this.getDrs();
        });
    },
    applyMinimumBonus() {
      let drsId = 0;
      if (this.currentDrsId === 0) {
        drsId = this.$route.params.id;
      } else {
        drsId = this.currentDrsId;
        this.$router.push(`/drs/view/${drsId}`);
      }
      axios.get(`/dailyReturnSheets/applyMinimumBonus/${drsId}.json?token=${this.token}`)
        .then(() => {
          this.getDrs();
        });
    },
    removeMinimumBonus() {
      let drsId = 0;
      if (this.currentDrsId === 0) {
        drsId = this.$route.params.id;
      } else {
        drsId = this.currentDrsId;
        this.$router.push(`/drs/view/${drsId}`);
      }
      axios.get(`/dailyReturnSheets/removeMinimumBonus/${drsId}.json?token=${this.token}`)
        .then(() => {
          this.getDrs();
        });
    },
    getDrs() {
      let drsId = 0;
      if (this.currentDrsId === 0) {
        drsId = this.$route.params.id;
      } else {
        drsId = this.currentDrsId;
        this.$router.push(`/drs/view/${drsId}`);
      }
      axios.get(`/dailyReturnSheets/getView/${drsId}.json?token=${this.token}`)
        .then((response) => {
          this.currentDrsId = parseInt(drsId);
          this.jobDailyReturnSheets = response.data.jobDailyReturnSheets;
          this.drs = response.data.dailyReturnSheet;
          this.bonuses = response.data.bonuses;
          this.date = response.data.dailyReturnSheet.work_date;
          this.totalBonus = response.data.dailyReturnSheet.bonus_value;
          this.sections = response.data.sections;
          if (response.data.dailyReturnSheet.minimum_bonus === true) {
            this.individualBonus = (20 / response.data.dailyReturnSheet.number_in_gang);
          } else {
            let individualBonus = (response.data.dailyReturnSheet.bonus_value /
                response.data.dailyReturnSheet.number_in_gang);
            individualBonus = individualBonus * 100;
            individualBonus = Math.floor(individualBonus);
            individualBonus = (individualBonus / 100);
            this.individualBonus = individualBonus;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDrs();
  },
};
</script>
